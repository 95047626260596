.item-card-wrapper {
  display: flex;
}

.item-card {
  height: 100%;
  border: none !important;
  box-shadow: 0px 0px 8px 2px rgba(66, 68, 90, 1);
}

.img-card:hover {
  cursor: zoom-in;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.price-title {
  margin-top: auto;
}
